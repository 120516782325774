import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { TextAreaField } from '../../../../../Components/TextAreaField';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import handlePhonNumberFormatter, { getDateFormat, message, pageRows } from '../../../../../Utils/functions'
import Modal from '../../../../../Components/Modal/Modal';
import Spinner from '../../../../../Components/Loadig';
import ErrorModal from '../../../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../../../Components/SelectField';
import { TextField } from '../../../../../Components/TextField';
import Siniestros from '../../../../../Services/Siniestro/Siniestro';
import Catalogo from '../../../../../Services/Catalogos/Catalogo';

const RegistroSiniestroCard = React.forwardRef(({ requiereCards }, ref) => {
    const { refFormik } = useRef();
    const { id, generalid, siniestroid } = useParams();
    const [collapsible, setCollapsible] = useState(false);
    const navigate = useNavigate();

    const { fechaEntrega,
        nombreYear,
        vin,
        color,
        nombreMarca,
        kilometraje,
        nombreModelo,
        procedenciaAuto,
        nombreVersion,
        numContrato,
        numSolicitud } = useSelector((state) => state.DataVinSlice);

    const { state } = useLocation();

    let initial = {
        siniestroId: id,
        reportaNombre: "",
        telefonoMovil: "",
        telefonoFijo: "",
        telefonoTrabajo: "",
        correoElectronico: "",
        siniestroTipoId: "",
        siniestroProcesoId: "",
        cuentaFolioAseguradora: true,
        folioAseguradora: "",
        comentarios: "",
        reporteAutoridades: "",
        requiereGrua: false,
        unidadDetenida: false,
        fechaReporte: "",
        reporteAseguradora: "",
        denunciaMinisterioPublico: "",
        numeroAveriguacion: "",
        fechaDenuncia: "",
        denunciaAcreditacion: "",
        folioDenuncia: "",
        concluido: false,
        color: "default",
        fechaInicio: "",
        fechaFin: "",
        //Stepper Update        
        paso: 1,
        porcentaje: 100,
        status: 2,
        acreditacionConcluido: false
        //procesoId: state.procesoId
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);

    const dataBoolean = [
        { value: true, label: 'Si' },
        { value: false, label: "No" }
    ]
    const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
    const opcionesBoolT = [{ value: true, label: 'Si' }, { value: false, label: 'No' }];
    const [dataTipoSiniestro, setDataTipoSiniestro] = useState([]);
    const [UnidadDetenida, setUnidadDetenida] = useState();
    const [formDisabled, setFormDisabled] = useState(false);

    useEffect(() => {
        handleGetAllSiniestroById()
    }, []);

    const handleGetAllSiniestroById = async () => {
        setLoading(true);
        await Siniestros.GetSiniestroById(id)
            .then(resp => {
                let item = resp.data;
                let newData = {
                    siniestroId: id,
                    reportaNombre: item?.reportaNombre ?? "",
                    telefonoMovil: item?.telefonoMovil ?? "",
                    telefonoFijo: item?.telefonoFijo ?? "",
                    telefonoTrabajo: item?.telefonoTrabajo ?? "",
                    correoElectronico: item?.correoElectronico ?? "",
                    siniestroTipoId: item?.siniestroTipoId ?? "",
                    siniestroTipo: item?.siniestroTipo ?? "",
                    siniestroProcesoId: item?.siniestroProcesoId ?? "",
                    unidadDetenida: item?.unidadDetenida ?? false,
                    requiereGrua: item?.requiereGrua ?? false,
                    // unidadDetenida: "",                        
                    cuentaFolioAseguradora: true,
                    folioAseguradora: item?.folioAseguradora ?? "",
                    // folio: "",
                    comentarios: item?.comentarios ?? "",

                    reporteAutoridades: item?.reporteAutoridades ?? "",
                    fechaReporte: item?.fechaReporte ?? "",
                    reporteAseguradora: item?.reporteAseguradora ?? "",
                    denunciaMinisterioPublico: item?.denunciaMinisterioPublico ?? "",
                    numeroAveriguacion: item?.numeroAveriguacion ?? "",
                    fechaDenuncia: item?.fechaDenuncia ?? "",
                    denunciaAcreditacion: item?.denunciaAcreditacion ?? "",
                    folioDenuncia: item?.folioDenuncia ?? "",

                    concluido: item?.concluido ?? false,
                    color: "default",
                    fechaInicio: item?.fechaInicio ?? "",
                    fechaFin: item?.fechaFin ?? "",
                    //Stepper Update        
                    paso: 1,
                    porcentaje: 100,
                    status: 2,
                    //procesoId: state.procesoId
                }
                setInitialValue({ ...initialValue, ...newData });
                if(item.concluido){
                    if(item.requiereGrua & item.unidadDetenida == false){
                        requiereCards({recoleccion: true, resguardo: false})
                    }else{
                        requiereCards({recoleccion: false, resguardo: true})
                    }
                    
                }
                //console.log(initialValue, "Valor inicial")
                //handleConcluido({ concluido: false,requiereGrua : item?. });
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    }

    const submitForm = async (values) => {
        setLoading(true);
        await Siniestros.UpdateSiniestroRobo(values)
            .then(res => {
                if (res.data.data.ban === 1) {
                    setLoading(false);
                    toast.success(message("Correcto!", res.data.data.mensaje));
                    handleGetAllSiniestroById()
                } else {
                    setLoading(false);
                    toast.error(message("¡Error!", res.data.data.mensaje));
                }
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error, al intentar actualizar el registro de cliente desiste!"));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const validate = Yup.object().shape({

        unidadDetenida: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        folioAseguradora: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        correoElectronico: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
                    .trim("El texto no debe empezar ni terminar con espacios")
                    .strict(true)
                    .email("Ingrese un email valido."),
            }),
        telefonoMovil: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
                    .min(14, "Se requiere formato correcto (Ejemplo: (81)-2514–7080)"),
            }),
        reportaNombre: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string().required("El campo es requerido.")
            }),
        comentarios: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string().required("El campo es requerido.")
            })

    });
    const handleUploadFile = () => {
        navigate(`/seguro/siniestros/datadocs/fSiniestro/eLevSin/${state?.folio}/${id}`, {
            state: { vin: vin, nombreMarca: nombreMarca, nombreModelo: nombreModelo, nombreVersion: nombreVersion, nombreYear: nombreYear }

        });
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                validationSchema={validate}
                initialValues={initialValue}
                onSubmit={(values) => {
                    submitForm(values);
                }}
            >
                {({ isValid, values, setFieldValue, errors }) => (
                    <>
                        <div className='row mx-md-4 my-4'>
                            <div className="col-12 px-md-3">
                                <div className="p-4 white-wrapper mb-2">
                                    <div
                                        className="row mt-2 px-4"
                                        data-bs-toggle="collapse"
                                        href="#collapseInfo"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="collapseInfo"
                                        onClick={() => setCollapsible(!collapsible)}
                                    >
                                        <div className="col-11">
                                            <h6><span className={`semaforo semaforo-${initialValue?.concluido ? "green" : "default"}`}></span> <strong>Registro de siniestro</strong></h6>
                                        </div>
                                        <div className="col-1 text-end">
                                            <i className={!collapsible ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                        <div className='col-12'>
                                            <small>{initialValue.concluido ? "Concluido" : "En Proceso"}</small>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapseInfo">
                                        <Form>
                                            <div className="separator"></div>
                                            <div className="row mt-2 px-4 mb-2">

                                                <div className="col-3">
                                                    <small><strong>Detalle de contacto</strong></small>
                                                </div>

                                                <div className="col-9">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            {
                                                                initialValue.concluido ?
                                                                    <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                    : <button
                                                                        className='btn'
                                                                        disabled={initialValue?.concluido ? true : !(isValid)}
                                                                        type='submit'>
                                                                        Guardar
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-12 col-lg-3">
                                                    <TextField
                                                        id="utilidad"
                                                        disabled={initialValue?.concluido}
                                                        label="Nombre de quien reporta"
                                                        holder="Escribe"
                                                        name="reportaNombre"
                                                        type="text" />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <TextField id="utilidad" disabled={initialValue?.concluido} label="Teléfono móvil" holder="Escribe" name="telefonoMovil" type="text" onChange={(event) => {
                                                        setFieldValue("telefonoMovil", handlePhonNumberFormatter(event.target.value));
                                                    }} />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <TextField id="utilidad" disabled={initialValue?.concluido} label="Teléfono fijo" holder="Escribe" name="telefonoFijo" type="text" onChange={(event) => {
                                                        setFieldValue("telefonoFijo", handlePhonNumberFormatter(event.target.value));
                                                    }} />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <TextField id="utilidad" disabled={initialValue?.concluido} label="Teléfono de trabajo" holder="Escribe" name="telefonoTrabajo" type="text" onChange={(event) => {
                                                        setFieldValue("telefonoTrabajo", handlePhonNumberFormatter(event.target.value));
                                                    }} />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-12 col-lg-3">
                                                    <TextField id="utilidad" disabled={initialValue?.concluido} label="Correo electrónico" holder="Escribe" name="correoElectronico" type="text" />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4 mb-2">
                                                <div className="col-auto">
                                                    <small className='fw-bold'>Detalle de siniestro</small>
                                                </div>
                                                <div className="col-auto">
                                                    <fieldset
                                                        disabled={initialValue?.concluido}
                                                        onClick={handleUploadFile}
                                                        className='btn btn-gray'>
                                                        <i className="icon-light fa fa-file "></i> Subir documento
                                                    </fieldset>
                                                </div>
                                            </div>


                                            <div className="row mt-2 px-4">
                                                <div className="col-12 col-lg-3">
                                                    <SelectField id="unidadDetenida"
                                                        label="Unidad detenida por autoridad"
                                                        name="unidadDetenida"
                                                        disabled={initialValue?.concluido}
                                                        items={opcionesBoolT}
                                                        onChange={(event) => {
                                                            setFieldValue("unidadDetenida", event.value);
                                                            setUnidadDetenida(event.value);
                                                        }} />
                                                </div>
                                                {
                                                    !values.unidadDetenida &&
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="requiereGrua"
                                                            label="¿Se requiere Grúa?"
                                                            name="requiereGrua"
                                                            disabled={initialValue?.concluido}
                                                            items={opcionesBoolT}
                                                            onChange={(event) => {
                                                                setFieldValue("requiereGrua", event.value);
                                                            }} />
                                                    </div>
                                                }
                                                <div className="col-12 col-lg-3">
                                                    <SelectField id="cuentaFolioAseguradora"
                                                        label="¿Cuenta con folio aseguradora?"
                                                        name="cuentaFolioAseguradora"
                                                        disabled={true}
                                                        items={opcionesBoolT}
                                                        onChange={(event) => {
                                                            setFieldValue("cuentaFolioAseguradora", event.value);
                                                        }} />
                                                </div>
                                                {/* {
                                                        values.cuentaFolioAseguradora === 1 && */}
                                                <div className="col-12 col-lg-3">
                                                    <TextField id="folioAseguradora"
                                                        disabled={initialValue?.concluido}
                                                        label="Folio siniestro aseguradora"
                                                        holder="Escribe"
                                                        name="folioAseguradora"
                                                        type="text"
                                                        onChange={(event) => {
                                                            setFieldValue("folioAseguradora", event.target.value);
                                                        }} />
                                                </div>
                                                {/* } */}
                                            </div>


                                            <div className="row mt-2 px-4">
                                                <div className="col-12 col-lg-6">
                                                    <TextAreaField
                                                        id="comentarios"
                                                        label="Comentarios"
                                                        disabled={initialValue?.concluido}
                                                        className="form-control col-12"
                                                        rows="3"
                                                        name="comentarios"
                                                        type="text"
                                                        holder="Escribe"
                                                        onChange={(event) => {
                                                            setFieldValue("comentarios", event.target.value);
                                                        }} />
                                                </div>
                                            </div>

                                            <div className="row mt-2 px-4">
                                                <div className="col-12 col-lg-6">
                                                    <Field className="form-check-input" disabled={initialValue.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                        setFieldValue("dictamenRegistrado", event.target.value);
                                                    }} />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                </div>
                                            </div>

                                            <div className="row mt-2 px-4 d-flex justify-content-end">
                                                <div className="col-auto">
                                                    {
                                                        initialValue.fechaInicio !== "" &&
                                                        <small className='text-body-tertiary'>Inicio de proceso: {initialValue?.fechaInicio}</small>
                                                    }
                                                </div>
                                                <div className="col-auto">
                                                    {
                                                        initialValue.concluido &&
                                                        <small className='text-body-tertiary'>Fin de proceso: {initialValue?.fechaFin}</small>
                                                    }
                                                </div>
                                            </div>

                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
                }
            </Formik>

        </>
    )
})
export default RegistroSiniestroCard