import React, { useEffect, useRef, useState } from 'react';
import Modal from '../../../../../Components/Modal/Modal';
import { TextField } from '../../../../../Components/TextField';
import { message } from '../../../../../Utils/functions';
import SeguroSiniestrosService from '../../../../../Services/Seguros/SeguroSiniestrosService';
import { ErrorMessage, useField } from 'formik';

const ModalCostos = ({ isOpen, setIsOpen, item, handleGetAll, toast, SiniestroResguardoUnidadId }) => {
    const refbtn = useRef();

    // Inicializar los valores del formulario
    const [formData, setFormData] = useState({
        SiniestroResguardoUnidad_CostoAdicionalId: item.SiniestroResguardoUnidad_CostoAdicionalId ?? "",
        siniestroResguardoUnidadId: SiniestroResguardoUnidadId ?? "",
        valuacionRegistroGastosId: item?.valuacionRegistroGastosId ?? "",
        concepto: item?.concepto ?? "",
        subTotal: item?.subTotal ?? 0,
        iva: item?.iva ?? 0,
        total: item?.total ?? 0,
    });

    const [errors, setErrors] = useState({});

    // Manejo del cambio en los inputs
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // Recalcular el total si cambia el subtotal o el IVA
        if (name === "subTotal" || name === "iva") {
            const subTotal = name === "subTotal" ? parseFloat(value) || 0 : parseFloat(formData.subTotal) || 0;
            const iva = name === "iva" ? parseFloat(value) || 0 : parseFloat(formData.iva) || 0;
            const total = subTotal + (subTotal * iva / 100);
            setFormData((prevData) => ({
                ...prevData,
                total: total.toFixed(2), // redondeo a 2 decimales
            }));
        }
    };

    useEffect(() => {
        console.log(item, "Lo qeu imprime al entrar costo")
    }, [])

    // Validación manual
    const validateForm = () => {
        const newErrors = {};
        if (!formData.concepto) {
            newErrors.concepto = "El campo es requerido.";
        }
        if (!formData.subTotal) {
            newErrors.subTotal = "El campo es requerido.";
        }
        if (!formData.iva) {
            newErrors.iva = "El campo es requerido.";
        }
        if (formData.iva > 99) {
            newErrors.iva = "El Valor de iva debe se ser menor o igual a 99";
        }
        if (formData.iva < 1) {
            newErrors.iva = "El Valor debe se ser mayor a 0";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Función para validar que el objeto tiene todas las propiedades llenas
    const handleSubmit = async () => {

        if (!validateForm()) return; // Si hay errores de validación, no continuar.

        refbtn.current.disabled = true;

        try {
            if (!isObjectEmpty(item)) {
                // Editar costo
                formData.siniestroResguardoUnidad_CostoAdicionalId = item.siniestroResguardoUnidad_CostoAdicionalId;
                const res = await SeguroSiniestrosService.actualizarResguardoCostosSiniestro(formData);
                if (res.data.ban === 1) {
                    toast.success(message("¡Correcto!", res.data.mensaje));
                } else {
                    toast.error(message("Error!", res.data.mensaje));
                }
            } else {
                formData.siniestroResguardoUnidad_CostoAdicionalId = item.siniestroResguardoUnidad_CostoAdicionalId;
                const res = await SeguroSiniestrosService.crearResguardoCostosSiniestro(formData);
                if (res.data.ban === 1) {
                    toast.success(message("¡Correcto!", res.data.mensaje));
                } else {
                    toast.error(message("Error!", res.data.mensaje));
                }
            }
            handleGetAll(1);
            setIsOpen(false);
        } catch (error) {
            toast.error(message("Error!", error.message));
        } finally {
            refbtn.current.disabled = false;
        }
    };

    const isObjectEmpty = (data) => {
        return !data ||
            !data.siniestroResguardoUnidad_CostoAdicionalId ||
            !data.siniestroResguardoUnidadId ||
            !data.concepto ||
            data.subTotal === null ||
            data.iva === null ||
            data.total === null;
    };

    return (
        <Modal isOpen={isOpen} color='#fff'>
            <section className='d-flex justify-content-between mx-4'>
                <section className='py-2 d-flex flex-column'>
                    <div className='d-flex justify-content-between'>
                        <span><strong>Registrar costo</strong></span>
                    </div>
                    <span className='mt-2 fs-6'>Completa todos los campos para el registro del costo.</span>
                </section>
            </section>
            <section className='my-4 mx-4' style={{ width: '30rem' }}>
                <div className="mb-3 row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        {/* <TextField
                            label="Concepto"
                            name="concepto"
                            type="text"
                            holder="Escribe"
                            value={formData.concepto}
                            onChange={handleChange}
                            error={errors.concepto}
                        /> */}
                        <label htmlFor="">Concepto</label>
                        <input type="text" className='form-control' value={formData.concepto} onChange={handleChange} name="concepto" />
                        <span className='error'>{errors.concepto}</span>

                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        {/* <TextField
                            label="Subtotal"
                            name="subTotal"
                            type="number"
                            holder="Escribe"
                            value={formData.subTotal}
                            onChange={handleChange}
                            error={errors.subTotal}
                        /> */}
                        <label htmlFor="">Subtotal</label>
                        <input type="text" className='form-control' value={formData.subTotal} onChange={handleChange} name="subTotal" />
                        <span className='error'>{errors.subTotal}</span>
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        {/* <TextField
                            label="IVA (%)"
                            name="iva"
                            type="number"
                            holder="Escribe"
                            value={formData.iva}
                            onChange={handleChange}
                            error={errors.iva}
                        /> */}
                        <label htmlFor="">IVA (%)</label>
                        <input type="text" className='form-control' value={formData.iva} onChange={handleChange} name="iva" />
                        <span className='error'>{errors.iva}</span>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <TextField
                            label="Total"
                            name="total"
                            type="number"
                            holder="Escribe"
                            value={formData.total}
                            disabled={true} // Total es calculado
                        />
                    </div>
                </div>
                <section className='my-3'>
                    <button className='btn btn-outline' type='reset' onClick={() => setIsOpen(false)}>
                        Cancelar
                    </button>
                    <button
                        ref={refbtn}
                        className='btn mx-2'
                        onClick={handleSubmit}
                        type='button'
                    >
                        {formData.valuacionRegistroGastosId === "" ? "Guardar" : "Editar"}
                    </button>
                </section>
            </section>
        </Modal>
    );
};

export default ModalCostos;
